<template>
  <div v-if="!isPending" class="orders-summery">
    <div class="tools-bar-btns" style="width:100%;">
      <Button
        label="הצג הכל"
        class="p-button-secondary"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px; color:#fff"
        @click="ordersShowAll"
      />
      <Button
        label="הצג חדשים"
        class="p-button-info"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="ordersShowNew"
      />
      <Button
        label="הצג טופלו"
        class="p-button-success"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="ordersShowDone"
      />
    </div>
    <div class="branches-list">
      <Dropdown
        v-model="selectedBranche"
        :options="branchesNames"
        placeholder="סנן לפי סניף"
      />
    </div>
    <div class="union-orders">
      <Button
        :label="computeUnionBtnContent"
        class="p-button-help"
        style="width:100%"
        @click="handleShowCheckbox"
      />
    </div>
    <div class="finish-union">
      <Button
        v-if="showCheckbox"
        label="סיים איחוד"
        class="p-button-success"
        style="width:100%"
        @click="finishUnion"
      />
    </div>
    <h1 v-if="filteredOrders.length==0">לא קיימות רשומות</h1>
    <template v-for="order in filteredOrders" :key="order.createdAt">
      <div class="orders-summery-line" @click="showOrderToPrint(order)">
        <button @click.stop="handle_send_to_dahan(order)" v-if="order.order_number" class="dahan-btn">
          שליחה לדהן
        </button>
        <div v-if="order.order_number" class="order-number">
          מס הזמנה: {{order.order_number}}
        </div>
        <div class="p-field-checkbox" v-if="showCheckbox">
          <Checkbox
            id="city1"
            :name="order.branche.name"
            :value="order"
            v-model="ordersToMerge"
          />
        </div>
        <div
          class="order-status"
          style="display:flex; gap: 10px; cursor: pointer;"
        >
          <p style="font-weight:500;">סטטוס:</p>
          <p v-if="order.status == 'open'" style="color:blue">חדש</p>
          <p v-if="order.status == 'close'" style="color:green">טופל</p>
          <Checkbox
                @click.stop
                name="order"
                :value="order.orderId"
                v-model="selected_orders_for_delete"
              />
        </div>
        <div class="order-date" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">תאריך:</p>
          <p>{{ order.createdAt.toDate().toLocaleString() }}</p>
        </div>
        <div class="order-branch" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">סניף:</p>
          <p>{{ order.branche }}</p>
        </div>
        <div class="order-products" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">מס מוצרים:</p>
          <p>{{ order.products.length }}</p>
        </div>
      </div>
    </template>

    <Dialog
      header="מחק הזמנה"
      v-model:visible="showOrderDelete"
      :modal="true"
      position="center"
    >
      <div
        class="delete-order"
        style="display:flex; flex-direction:column; gap: 15px;"
      >
        <p>האם אתה בטוח?</p>
        <Button
          label="טעות, סגור"
          style="width:200px; height:40px; font-size: 1.1rem;"
          class="p-button-info"
          @click="showOrderDelete = !showOrderDelete"
        />
        <Button
          label="מחק לגמריי"
          style="width:200px; height:40px; font-size: 1.1rem;"
          class="p-button-danger"
          @click="deleteOrderFinal"
        />
        <Button
          label="מחק ושחזר מלאי"
          style="width:200px; height:40px; font-size: 1.1rem;"
          class="p-button-danger"
          @click="deleteOrderRestore"
        />
      </div>
    </Dialog>

    <Dialog
        header="חלון מחיקה"
        v-model:visible="showDeleteDialog"
        :style="{ width: '30vw' }"
        position="bottom"
    >
        <p class="p-m-0">האם את/ה בטוח/ה שברצונך למחוק את ההזמנות המסומנות?</p>
        <template #footer>
        <Button
            label="לא"
            @click="handleDeleteChecks('לא')"
            class="p-button-success"
        />
        <Button
            label="כן"
            @click="handleDeleteChecks('כן')"
            class="p-button-danger"
        />
        </template>
  </Dialog>
  </div>
  <div>
    <!-- orders to print -->
    <Dialog
      header="הזמנה"
      v-model:visible="showOrderPrint"
      :modal="true"
      position="top"
    >
      <div id="orderToShow">
        <div class="print">
          <Button
            id="print-order-hide"
            label="הדפס הזמנה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="printOrder"
          />
          <!-- <Button
            id="print-order-hide"
            label="Распечатать"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="printOrderInRussian"
          /> -->
        </div>
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p v-if="orderToPrint.order_number">מס הזמנה: {{orderToPrint.order_number}}</p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
        </div>
        <div
          class="notes-order"
          style="display:flex; gap:5px; flex-direction:column; margin-top:10px; background-color:whitesmoke; border-radius:5px; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size: 1.2rem; font-weight:500; direction:rtl;">
            הערות להזמנה
          </p>
          <p style="font-size: 1.1rem;  direction:rtl;">
            {{ orderToPrint.notes }}
          </p>
        </div>

        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; margin-top:1rem; width:100%;  font-size:1.3rem; font-weight:500;"
          >
            <p style="width:20%; text-align:center;">ברקוד</p>
            <p style="width:20%; text-align:center;">קוד</p>
            <p style="width:20%; text-align:center;">שם</p>
            <p style="width:20%; text-align:center;">כמות</p>
            <p style="width:20%; text-align:center;">מחיר</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%;"
          ></div>

          <template v-for="product in orderToPrint.products" :key="product.id">
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
            >
              <div style="width:20%;">
                <img style="max-width:100%;" :src="`https://api.qrserver.com/v1/create-qr-code/?data=${product.barkod}&amp;size=100x100`" alt="" />
              </div>
              <p style="width:20%;">{{ product.barkod }}</p>
              <p style="width:20%;">{{ product.ItemName }}</p>
              <p style="width:20%;">{{ product.currentOrder }}</p>
              <p style="width:20%;">{{ product.cost|| "לא צויין" }} &#8362;</p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%; margin-bottom:10px;"
            ></div>
          </template>
        </div>

        <div
          class="print-tools"
          style="width:90vw; display:flex; gap:10px; justify-content:center; margin-top:1rem;"
        >
          <Button
            id="mark-btn-hide"
            label="סמן כטופל"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="flagOrderFinished"
          />
          <Button
            id="delete-order-hide"
            label="מחק הזמנה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-danger"
            @click="showOrderDelete = !showOrderDelete"
          />
        </div>
      </div>

      <div id="orderToPrint" v-show="false">
        <div
          class="order-title"
          style="text-align:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p style="position:fixed; top:-19px; right:5px; color:red;">
            מזהה הזמנה:{{
              orderToPrint.orderId
                .split("")
                .filter(char => char.match(/(\d+)/))
                .join("")
            }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
          <p v-if="orderToPrint.order_number">מס הזמנה: {{orderToPrint.order_number}}</p>
        </div>
        <table
          style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%; direction:rtl; page-break-inside:auto"
        >
          <tr>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              ברקוד
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              קוד
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              שם
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              כמות
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              מחיר
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              משקל
            </th>
          </tr>

          <template v-for="(product) in orderToPrint.products" :key="product.id">
            <tr style="page-break-inside:avoid; page-break-after:auto">
              <td style="border: 1px solid #dddddd; text-align: left; width:25%; padding: 8px; text-align: right;">
                <img style="width:64px; height:64px;" :src="`https://api.qrserver.com/v1/create-qr-code/?data=${product.barkod}&amp;size=100x100`">
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:16.6%; padding: 8px; text-align: right;"
              >
                {{ product.barkod }}
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:16.6%; padding: 8px; text-align: right;"
              >
                {{ product.ItemName }}
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:16.6%; padding: 8px; text-align: right;"
              >
                {{ product.currentOrder }}
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:16.6%; padding: 8px; text-align: right;"
              >
                {{ product.cost || 'לא צויין' }} &#8362;
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:16.6%; padding: 8px; text-align: right;"
              ></td>
            </tr>
          </template>
        </table>
      </div>

      <div id="orderToPrintRussian" v-show="false">
        <div
          class="order-title"
          style="text-align:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p style="position:fixed; top:-19px; right:5px; color:red;">
            מזהה הזמנה:{{
              orderToPrint.orderId
                .split("")
                .filter(char => char.match(/(\d+)/))
                .join("")
            }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
        </div>
        <table
          style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%; direction:rtl;"
        >
          <tr>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              קוד
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              שם
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              כמות
            </th>
            <th
              style="border: 1px solid #dddddd;  text-align: left; padding: 8px; text-align: right;"
            >
              משקל
            </th>
          </tr>

          <template v-for="product in orderToPrint.products" :key="product.id">
            <tr>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:25%; padding: 8px; text-align: right;"
              >
                {{ product.barkod }}
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:25%; padding: 8px; text-align: right;"
              >
                {{ product.ItemNameRussian }}
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:25%; padding: 8px; text-align: right;"
              >
                {{ product.currentOrder }}
              </td>
              <td
                style="border: 1px solid #dddddd; text-align: left; width:25%; padding: 8px; text-align: right;"
              ></td>
            </tr>
          </template>
        </table>
      </div>

      <!-- ========= only for print ========= -->
    </Dialog>
  </div>

  <div v-if="isPending" class="loader">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/rosman2021-d33ae.appspot.com/o/reload%2Forange.gif?alt=media&token=3788e42b-cee9-4abe-8edb-171f85e4e043"
    />
  </div>

   <!-- JUST FOR MAIL (to Dahan) -->
    <div v-if="orderToPrint && orderToPrint.order_number" id="dahan-mail" style="display:none;">
      <h1 style="text-align:center;">
        <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2">
      </h1>
      <h1 style="text-align:center;">מס' הזמנה: {{orderToPrint.order_number}} - (עותק)</h1>
      <h2 style="text-align:center;">הזמנה חדשה: סניף {{orderToPrint.branche}}</h2>
      <h3 style="text-align:center;">תאריך: {{new Date(orderToPrint.createdAt.seconds * 1000).toLocaleString('he')}}</h3>
      <table
        style="width:100%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; color:#333;">יצרן</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">שם</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">כמות</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">משקל</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; color:#333;">מחיר</th>
        </tr>
        <template
            v-for="product in orderToPrint.products"
            :key="product.barkod"
          >
              <tr>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">{{ product.ItemName }}</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">{{ product.currentOrder }}</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
              </tr>
        </template>
      </table>
    </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Dialog from "primevue/dialog";
import {projectFirestore,projectFunctions} from '../../../../../../firebase/config'
import swal from "sweetalert";
import {alert_confirm} from '../../../../../../Methods/Msgs'
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";

/* eslint-disable */
export default {
  components:{Dialog,Checkbox,Dropdown},
  emits:["onClose"],
  setup(_,{emit}){
    const showDeleteDialog = ref(false)
    const selected_orders_for_delete = ref([])
    const isPending = ref(false)
    let set = ref(new Set())
    const showFinishBtn=ref(false)
    const showCheckbox=ref(false)
    const ordersToMerge=ref([])
    const orders=ref([])
    const ordersFilter=ref('')
    const showOrderPrint = ref(false);
    const orderToPrint = ref(null)
    const showOrderDelete = ref(false)
    const unionBtnContent = ref('אחד הזמנות')
    const selectedBranche=ref('הצג הכל')
    const branchesNames=ref([
      'הצג הכל',
      'מרכז',
      'רבמ"ד',
      'כרמיאל',
      'נביאים',
      'סילבר',
      'רוטשילד',
      'הרצל',
      'טעם טוב מעלות'
    ])
    
    const delete_checked_order = async(order_id) => {
         await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .doc(order_id)
        .delete()
    }

    const handleDeleteChecks = async(option)=>{
      const promises = []
      if(option=='לא'){
          showDeleteDialog.value=false
          selected_orders_for_delete.value=[]
      }
      if(option=='כן'){

          for (const order_id of selected_orders_for_delete.value){
              promises.push(delete_checked_order(order_id))
          }

          await Promise.all(promises)
          
          for(const order_id of selected_orders_for_delete.value){
            const index = orders.value.findIndex(_record=>_record.orderId == order_id)
            if(index!=-1){
              orders.value.splice(index,1)
            }
          }

          showDeleteDialog.value=false
          selected_orders_for_delete.value=[]
      }
    }

    const handle_send_to_dahan = (order)=>{
      orderToPrint.value = order
      alert_confirm(`האם לשלוח עותק מהזמנה ${orderToPrint.value.order_number}?`)
      .then((res)=>{
        if(res.isConfirmed){
          sendEmailToDahan()
        }
      })
    }
    function sendEmailToDahan() {
      //dahank62@gmail.com
      projectFunctions.httpsCallable('send_email')({
        html: document.getElementById("dahan-mail").innerHTML,
        destinations:["mosescorcias@gmail.com","setonaut@gmail.com","veg.fruitrosman@gmail.com"],
        from:"רוסמן הזמנות",
        title:`הזמנת ירקות סניף ${orderToPrint.value.branche}-(עותק), מס הזמנה: ${orderToPrint.value.order_number}`,
      })
    }
    const handleShowCheckbox = ()=>{
      showCheckbox.value=!showCheckbox.value
      if(!showCheckbox.value){
        unionBtnContent.value="אחד הזמנות"
      }else{
        unionBtnContent.value="בטל איחוד"
        ordersToMerge.value=[]
      }
    }

    const computeUnionBtnContent=ref(computed(()=>{
      return unionBtnContent.value
    }))

    

    const filteredOrders = ref(computed(() => {
      if(selectedBranche.value == 'הצג הכל'){
        if(ordersFilter.value == ''){
          return orders.value
        }
        if(ordersFilter.value == 'open'){
          return orders.value.filter(order => {
            return order.status == 'open'
          })
        }
        if(ordersFilter.value == 'close'){
          return orders.value.filter(order => {
            return order.status == 'close'
          })
        }
      }
      else{
        let temp = orders.value.filter(order => {
          return order.branche.includes(selectedBranche.value)
        })
        if(ordersFilter.value == ''){
          return temp
        }
        if(ordersFilter.value == 'open'){
          return temp.filter(order => {
            return order.status == 'open'
          })
        }
        if(ordersFilter.value == 'close'){
          return temp.filter(order => {
            return order.status == 'close'
          })
        }
      }
    }))

    const ordersShowAll=()=> {
      ordersFilter.value = "";
    }
    const ordersShowDone=()=> {
      ordersFilter.value = "close";
    }
    const ordersShowNew=()=> {
      ordersFilter.value = "open";
    }

    const showOrderToPrint = order=>{
      if(!showCheckbox.value){
      
       order.products= order.products.sort((a,b)=>{
         return a.department > b.department ? 1 : a.department<b.department ? -1 :0 
       })

       orderToPrint.value = order
       console.log(orderToPrint.value);
       showOrderPrint.value = true
       
      }
      
    }

 
    const flagOrderFinished=async()=>{
      let docId;
      let docData;
      let docProducts=[]
      try{
        const res= await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .where("orderId", "==", orderToPrint.value.orderId)
        .get()

        res.forEach(doc=>{
          docId = doc.id
          docData = doc.data()
          docProducts=[...docData.products]
        })
        console.log(docProducts);

        docProducts.forEach(async product=>{

           const productRef= projectFirestore
          .collection("Applications")
          .doc("iW7LKA8GsRduHDOSFxq1")
          .collection("Products")
          .doc(product.barkod.toString())
  
          const doc = await productRef.get()
          
            let stock = doc.data().stock
            let openStock = doc.data().openStock
            if(openStock>stock && stock>0){
              openStock=stock
            }if(stock<=0){
              openStock=0
            }
            await projectFirestore
            .collection("Applications")
            .doc("iW7LKA8GsRduHDOSFxq1")
            .collection("Products")
            .doc(product.barkod.toString())
            .update({
              openStock,
              stock
            })
        })
  
        await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .doc(docId)
        .update({
          status:"close"
        })

        swal("סטטוס הזמנה", "הפכת סטטוס הזמנה זו לטופל", "success").then(()=>{
          showOrderPrint.value = false
        })
        

      }catch(err){
        swal(`${err.message}`, "סטטוס הזמנה לא השתנה עקב תקלה נא צור קשר עם תמיכה הטכנית", "error");
      }
    }

    const deleteOrderFinal=async()=>{
      console.log( orderToPrint.value.orderId);
      try{
        const res= projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .doc(orderToPrint.value.orderId)
        .delete()

        swal("סטטוס הזמנה", "הזמנה זו נמחקה בהצלחה", "success")
        .then(()=>{
          showOrderDelete.value=!showOrderDelete.value
          showOrderPrint.value=!showOrderPrint.value
        })

      }catch(err){
        swal(`${err.message}`, "סטטוס הזמנה לא השתנה עקב תקלה נא צור קשר עם תמיכה הטכנית", "error");
      }
    }

    const deleteOrderRestore =async()=>{
     try{
      const orderId = orderToPrint.value.orderId;
      let docId;
      const orderRes = await projectFirestore
      .collection("Applications")
      .doc("iW7LKA8GsRduHDOSFxq1")
      .collection("Orders")
      .where("orderId","==",orderId)
      .get()

      orderRes.forEach(doc=>{
        docId=doc.id
      })

      await projectFirestore
      .collection("Applications")
      .doc("iW7LKA8GsRduHDOSFxq1")
      .collection("Orders")
      .doc(docId)
      .delete()

      orderToPrint.value.products.forEach((product)=>{
        const barkod = product.barkod
         projectFirestore
        .collection("Applications")
        .doc('iW7LKA8GsRduHDOSFxq1')
        .collection('Products')
        .doc(barkod.toString())
        .set({
          stock: product.stock,
          openStock: product.openStock
        },{merge:true})
      })
      
      swal("נמחק בהצלחה", "מלאי עודכן בחזרה", "success");
      showOrderDelete.value=!showOrderDelete.value
      showOrderPrint.value=!showOrderPrint.value
      emit("onClose")


    }catch(err){
      console.log(err.message);
       swal(`התרחשה שגיאה`,`${err.message}`, "error");
    }

     
    }


    const printOrder=()=>{
    const divContents = document.getElementById("orderToPrint").innerHTML;
      var a = window.open('', '', 'height=800, width=600'); 
    a.document.write('<html>'); 
    a.document.write('<style>'); 
    a.document.write('@media print{#print-order-hide {visibility: hidden; } #delete-order-hide { visibility: hidden; } #mark-btn-hide {visibility: hidden;}}')
    a.document.write('</style>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
    }
    const printOrderInRussian=()=>{
    const divContents = document.getElementById("orderToPrintRussian").innerHTML;
      var a = window.open('', '', 'height=800, width=600'); 
    a.document.write('<html>'); 
    a.document.write('<style>'); 
    a.document.write('@media print{#print-order-hide {visibility: hidden; } #delete-order-hide { visibility: hidden; } #mark-btn-hide {visibility: hidden;}}')
    a.document.write('</style>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
    }

    
    
    let snapshot
    const getOrders=()=>{
      isPending.value=true
     snapshot = projectFirestore
      .collection("Applications")
      .doc("iW7LKA8GsRduHDOSFxq1")
      .collection('Orders')
      .orderBy('createdAt','desc').onSnapshot(snap=>{
        let results = [];
        snap.docs.forEach(doc => {
        // must wait for the server to create the timestamp & send it back
        doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
      });


      orders.value=results
      isPending.value=false
      },err=>{
        console.log(err.message);
        orders.value = null;
      })
    } 
    onMounted(()=>{
        getOrders()
    })

    onUnmounted(()=>{
      if(snapshot){
        snapshot()
      }
    })


   
    const finishUnion=()=>{
      let error=false
      if(ordersToMerge.value.length<=1){
        error=true
        swal("אופס","עליך לבחור לפחות 2 הזמנות", "error");
      }
      else{
        ordersToMerge.value.forEach(order=>{
        try{
          const brancheName=order.branche.name
          if(!set.value.size==0 && !set.value.has(brancheName)){
            set.value.clear()
            error=true
            throw new Error("אתה לא יכול לבחור סניפים שונים")
          }else{
            set.value.add(brancheName)
          }
        }
        catch(err){
          console.error(err.message);
          swal(`${err.message}`, "אנא בחר סניפים עם אותו שם סניף כדי לעשות איחוד הזמנות", "error");
        }

        })
      }
      

      if(!error){
        const theMaxOrderLocation = findMaxOrder(ordersToMerge.value).index
        // union product will be here
        mergeProductToMaxOrder(ordersToMerge.value,theMaxOrderLocation)
      }
    }


    const mergeProductToMaxOrder=(ordersToMerge,index)=>{

      const theMaxOrder=ordersToMerge[index]
      const theRestOfOrders=ordersToMerge.filter(order=>order.orderId!=theMaxOrder.orderId)

      theRestOfOrders.forEach(order=>{
        // merge to max order all the rest orders
        theMaxOrder.products.push(...order.products)
      })
      

      preventDuplicates(theMaxOrder)
     
      theRestOfOrders.forEach(order=>{
        deleteTheRestofOrdersInDB(order.orderId)
      })

      updateUnionOrderInDB(theMaxOrder.orderId,theMaxOrder) 

      showCheckbox.value!=showCheckbox.value
    }

    const preventDuplicates=(theMaxOrder)=>{
      let tempArray = [];
      theMaxOrder.products.forEach(product=>{
        const index = tempArray.findIndex(item => item.barkod === product.barkod);
        if( index == -1 ){
          tempArray.push(product)
        }
        else{
          tempArray[index].currentOrder = Number(product.currentOrder)+Number(tempArray[index].currentOrder);
        }
      });
      theMaxOrder.products = tempArray 
      tempArray=[]
    }

    const updateUnionOrderInDB=async(id,mergeOrders)=>{
      let docId;
      try{
        const res= await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .where("orderId", "==", id)
        .get()

        res.forEach(doc=>{
          docId=doc.id
        })

        await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .doc(docId)
        .update({
          products: mergeOrders.products
        })

        swal("איחוד בוצע בהצלחה", "הזמנות שאוחדו נמחקו והתאחדו להזמנה אחת", "success");
        

      }catch(err){
        swal(`${err.message}`, "אירעה שגיאה בזמן הנסיון לאחד הזמנות", "error");
      }
    }

    const deleteTheRestofOrdersInDB=async(id)=>{
      let docId;
      try{
        const res= await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .where("orderId", "==", id)
        .get()

        res.forEach(doc=>{
          docId=doc.id
        })

        await projectFirestore
        .collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection("Orders")
        .doc(docId)
        .delete()

      }catch(err){
        swal(`${err.message}`, "אירעה שגיאה כאשר השרת ניסה למחוק את שאר ההזמנות", "error");
      }
    
    }
    const findMaxOrder=(ordersToMerge)=>{
      const data={
        max:0,
        index:0
      }
      ordersToMerge.forEach((order,index)=>{
        if(order.products.length>data.max){
          data.max=order.products.length
          data.index=index
        }
      })

      return data
    }

    watch(selected_orders_for_delete,()=>{
          if(selected_orders_for_delete.value.length>0){
              showDeleteDialog.value = true
          }
          else {
              showDeleteDialog.value = false
          }
    })



    return{
        handle_send_to_dahan,
        printOrderInRussian,
        selectedBranche,
        branchesNames,
        finishUnion,
        showFinishBtn,
        showCheckbox,
        ordersToMerge,
        orders,
        getOrders,
        ordersShowAll,
        ordersShowDone,
        ordersShowNew,
        filteredOrders,
        showOrderToPrint,
        orderToPrint,
        showOrderPrint,
        flagOrderFinished,
        showOrderDelete,
        deleteOrderFinal,
        deleteOrderRestore,
        printOrder,
        unionBtnContent,
        handleShowCheckbox,
        computeUnionBtnContent,
        isPending,
        selected_orders_for_delete,
        showDeleteDialog,
        handleDeleteChecks,
      }
  }
};
</script>

<style scoped>
  .orders-summery {
      width: 80vw;
      height: 80vh;
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  .orders-summery-line {
    position: relative;
    width: 100%;
    border: 1px solid rgb(175, 166, 166);
  }
  .dahan-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    z-index: 10;
    border: 0;
    padding: 5px;
    border-radius: 5px;
    background: cornflowerblue;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
  }
  .order-number{
    position: absolute;
    bottom: 0;
    left: 5px;
    z-index: 10;
  }

  .tools-bar-btns{
    margin-top: 1.2rem;
    display: flex;
  }

  .p-field-checkbox{
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 5;
  }

  .print{
    display: flex;
    justify-content: space-around;
  }
  @media print { 
    
      #page-down {
      page-break-after: always;
      
    } 
    #print-order-hide { 
      visibility: hidden; 
    }
    #delete-order-hide { 
      visibility: hidden; 
    }
    #mark-btn-hide { 
      visibility: hidden; 
    }

  }

  .loader{
    width: 100%;
    height: 100%;
  }
  .loader img{
    width: 100%;
    height: 100%;
  }



</style>
