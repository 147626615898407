<template>
 
  <div class="manager-panel">
    <p style="color:#fff; font-size: 2rem">פאנל ניהול ירקות</p>
    <Button
      v-if="role>=99 && role!=105"
      label="ניהול פריטי ירקות"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-success"
      @click="showManageCatalog = !showManageCatalog"
    />
    <Button
       v-if="role>=99 && role!=105"
      label="ניהול הזמנות"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-success"
      @click="showOrders = !showOrders"
    />
     <Button
      label="ריכוז פריטים בהזמנות להיום"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-success"
      @click="show_orders_of_today = !show_orders_of_today"
    />
     <Button
      label="רשימת עלויות"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-success"
      @click="show_prices_list=!show_prices_list"
    />
    <Button
       v-if="role>=99 && role!=105"
      label="ניהול דיווחים"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-danger"
      @click="showReports = !showReports"
    />
    <Button
       v-if="role>=99 && role!=105"
      label="הדרכות"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-Info"
      @click="showGuids = !showGuids"
    />
    <div v-if="showManageCatalog" class="product-manager-dialog">
       <!-- <ProductManagerVeges /> -->
       <ProductManagerVegesNew />
       <div @click="showManageCatalog=false" class="product-manager-dialog-back-btn">
         <i class="material-icons">arrow_back</i>
       </div>
    </div>
  </div>
  <keep-alive>
    <PricesList @close="show_prices_list=false" v-if="show_prices_list"/>
  </keep-alive>
  <OrdersOfTodayMain @close="show_orders_of_today=false" v-if="show_orders_of_today" />

   <!-- <Dialog header="ניהול פריטים" v-model:visible="showManageCatalog">
    <ProductManagerVeges />
  </Dialog> -->
  <Dialog
    header="דוח הזמנות"
    v-model:visible="showOrders"
    :modal="true"
    position="top"
  >
    <OrdersManagerVeges @onClose="showOrders = !showOrders" />
  </Dialog>
  <Dialog
    header="הדרכות"
    v-model:visible="showGuids"
    :modal="true"
    position="top"
  >
    <VideoComponent videoId="123" @close="showGuids = !showGuids" />
  </Dialog>
  <Dialog
    header="ניהול דיווחים"
    v-model:visible="showReports"
    :modal="true"
    position="top"
  >
    <ManageReport />
  </Dialog>
</template>

<script>
/* eslint-disable */
import ProductManagerVeges from './components/ProductManagerVeges.vue'
import ProductManagerVegesNew from './components/ProductManagerVegesNew.vue'
import OrdersManagerVeges from './components/OrdersManagerVeges.vue'
import VideoComponent from '../../../guides/VideoComponent.vue'
import ManageReport from '../admin/components/ManageReport.vue'
import PricesList from '../admin/components/PricesList.vue'
import OrdersOfTodayMain from '../admin/components/Orders_of_today/OrdersOfTodayMain.vue'
import { computed, ref } from "vue";
import Dialog from "primevue/dialog";
import store from '../../../../../store'

export default {
  components: { Dialog,ProductManagerVeges,OrdersManagerVeges,VideoComponent,
  ManageReport,ProductManagerVegesNew,PricesList,OrdersOfTodayMain},
  setup() {
    const role = ref(computed(()=>{
      return store.state.role
    }))
    const show_prices_list = ref(false)
    const show_orders_of_today = ref(false)
    const showGuids = ref(false)
    const showManageCatalog = ref(false);
    const showOrders = ref(false);
    const showReports=ref(false)
    return { showManageCatalog, showOrders,showGuids,showReports,show_orders_of_today,show_prices_list,role };
  }
};
</script>

<style scoped>
.manager-panel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-manager-dialog{
  position: absolute;
  margin:0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-manager-dialog-back-btn{
  position: absolute;
  top: 5px;
  left:0;
  height: 25px;
  width: 25px;
  background: var(--purple);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

</style>
