<template>
  <div v-if="!state" class="yerakot-main">
    <div class="title">
      <p>פירות וירקות רוסמן</p>
    </div>
    <div v-if="!state" class="main-menu-options">
      <Button
        v-if="user_role > 99"
        label=" פאנל ניהול ירקות"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-success"
        @click="state='ניהול'"
      />
      <Button
        label="הזמנה חדשה ירקות"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-warning"
        @click="state='הזמנה חדשה'"
        v-if="user_role >= 25 && user_role != 105"
      />
      <Button
        v-if="user_role >= 25 && user_role !=105"
        label="ההזמנות שלי"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-warning"
        @click="showMyOrder=!showMyOrder"
      />
      <Button
        v-if="user_role >= 25 && user_role !=105"
        label="דווח על בעיה"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-danger"
        @click="show_new_reports=!show_new_reports"
      />
      <Button
        v-if="user_role >= 25 && user_role !=105"
        label="הדיווחים שלי"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-help"
        @click="show_my_reports=!show_my_reports"
      />
      
      <!-- <input type="file" @change="handle_import_from_csv"> -->
      
    <Dialog header="ההזמנות שלי" v-model:visible="showMyOrder" >
        <MyOrders />
    </Dialog>
    <Dialog header="דיווח על בעיה" v-model:visible="show_new_reports" >
        <NewReport />
    </Dialog>
    <Dialog header="הדיווחים שלי" v-model:visible="show_my_reports" >
       <MyReports />
    </Dialog>
    </div>
  </div>
  <div v-if="state" class="yerakot-components">
      <NewOrderVeges v-if="state=='הזמנה חדשה'" @close="state=null" />
      <AdminVeges v-if="state=='ניהול'"/>
  </div>
  
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import NewOrderVeges from '../yerakot/components/NewOrderVeges.vue'
import AdminVeges from '../yerakot/components/admin/AdminVeges.vue'
import MyOrders from '../yerakot/components/MyOrders.vue'
import NewReport from '../yerakot/components/NewReport.vue'
import MyReports from '../yerakot/components/MyReports.vue'
import Dialog from 'primevue/dialog';
import store from '../../../store'
import {handle_back_button_on_browser} from '../../../Methods/Back_button_browser'
import XLSX from "xlsx";


export default {
    components:{NewOrderVeges,AdminVeges,MyOrders,Dialog,NewReport,MyReports},
    setup(){
        const {state} = handle_back_button_on_browser()
        const show_my_reports = ref(false)
        const show_new_reports = ref(false)
        const showMyOrder = ref(false)
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))


        //טעינת ירקות מקובץ אקסל
        const handle_import_from_csv = (e)=>{
          var file = e.target.files[0];
          if (!file) return;
          var FR = new FileReader();
          FR.onload = async function(e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
            var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            
            // header: 1 instructs xlsx to create an 'array of arrays'
            var result = XLSX.utils.sheet_to_json(firstSheet, { raw: false });
            
            // data preview
          for(const data of result){
            const values = {
              created_at:new Date(),
              full_name:'',
              worker_id:'111111111',
              start_date:'',
              end_date:'',
              hr_company:'',
              branche:'',
              notes:'',
              employee_recommend:'',
              employee_recommend_id:'',
              tashlum_1:'',
              amount_1:0,
              tashlum_2:'',
              amount_2:0,
              tashlum_3:'',
              amount_3:0,
              tashlum_4:'',
              amount_4:0,
              status:'פתוח'
            }

            if(data.amount_1) values.amount_1 = Number(data.amount_1)
            if(data.amount_2) values.amount_2 = Number(data.amount_2)
            if(data.amount_3) values.amount_3 = Number(data.amount_3)
            if(data.amount_4) values.amount_4 = Number(data.amount_4)
            if(data.tashlum_1) values.tashlum_1 = new Date(data.tashlum_1)
            if(data.tashlum_2) values.tashlum_2 = new Date(data.tashlum_2)
            if(data.tashlum_3) values.tashlum_3 = new Date(data.tashlum_3)
            if(data.tashlum_4) values.tashlum_4 = new Date(data.tashlum_4)
            if(data.end_date) values.end_date = new Date(data.end_date)
            if(data.worker_id) values.worker_id = data.worker_id
            values.full_name = data.full_name
            values.start_date = new Date(data.start_date)
            values.hr_company = data.hr_company
            values.branche = data.branche
          }
            
          };
          FR.readAsArrayBuffer(file);
        }

        // const save_data = async(data)=>{
        //   const doc =  projectFirestore.collection('Applications').doc('SpyoYmcAgbdKYsd8mJ66').collection('employee').doc()
        //   await doc.set({
        //     ...data,
        //     uid:doc.id
        //   })
        // }
        // const save_data_in_db = async(data)=>{
        //   await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1')
        //   .collection('Products').doc(data.barkod).set(data)
        // }
      
        
        return{handle_import_from_csv,user_role,state,showMyOrder,show_new_reports,show_my_reports,state}
    }
}
</script>

<style scoped>
    .yerakot-main,.yerakot-components{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1.5rem;
        overflow-y: auto;
    }
    .main-menu-options {
        width: 350px;
        height: 80vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
</style>