<template>
  <div class="prices-list">
      <div class="prices-list-wrapper slide-in-top">
          <div @click="handle_close" class="exit-btn">X</div>
          <div class="prices-list-wrapper-header">
               <h4> עלויות פריטי ירקות {{new Date().toLocaleDateString('he')}}</h4>
          </div>
          <div class="prices-list-wrapper-tools">
              <el-input style="width:30%" v-model="search" placeholder="חיפוש" />
             <i class="material-icons print-icon" @click="handle_print">print</i>
          </div>
          <div v-loading="is_pending" id="prices-list-wrapper-content" class="prices-list-wrapper-content">
            <table >
                <tr>
                    <th>ברקוד</th>
                    <th>שם פריט</th>
                    <th>מחיר נוכחי</th>
                    <th>מחיר לעדכון</th>
                    <th></th>
                </tr>
                <template v-for="item in filter_by_serach" :key="item.barkod">
                    <tr>
                        <td>{{item.barkod}}</td>
                        <td>{{item.ItemName}}</td>
                        <td v-if="item.cost>0" style="color:green;">{{item.cost}} &#8362; </td>
                        <td v-else style="color:red;">{{item.cost}} &#8362; </td>
                        <td>
                             <el-input-number v-model="item.temp_price"  :min="1" :step="0.01" :max="100"  />
                        </td>
                        <td>
                           <el-button class="noprint" @click="handle_update_price(item)" type="success">עדכון</el-button> 
                        </td>
                    </tr>
                </template>
            </table>
          </div>
          <div class="prices-list-wrapper-footer">
              <el-button @click="handle_update_all_prices" style="width:80%" type="success">עדכון כל הרשומות</el-button>
          </div>
      </div>
      <PriceListSummary v-if="show_summary" @finish="handle_update_all_choosen_prices" @close="show_summary=false" :summary_data="summary_data" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../../../../firebase/config'
import {slide_pop_error,slide_pop_successs} from '../../../../../../Methods/Msgs'
import PriceListSummary from './Price_list/PriceListSummary.vue'
import { computed } from '@vue/runtime-core'
export default {
    emits:['close'],
    components:{PriceListSummary},
    setup(_,{emit}){
        const is_pending = ref(false)
        const show_summary = ref(false)
        const summary_data = ref([])
        const items = ref([])
        const search = ref('')

        const filter_by_serach = ref(computed(()=>{
            if(search.value=='') return items.value
            return items.value.filter((item)=>{
                if(item.ItemName.includes(search.value)) return item
                if(item.barkod.includes(search.value)) return item
            })
        }))
        const handle_update_price = async(item)=>{
            if(!item.temp_price || item.temp_price==0){
                slide_pop_error('עליך להזין ערך הגדול מ-0 בשדה!')
            }else{
                await update_price_item_in_db(item)
            }
        }

        const handle_update_all_prices = ()=>{
            summary_data.value = items.value.filter(item=>item.temp_price && item.temp_price>0)
            if(summary_data.value.length==0){
                slide_pop_error('עליך לעדכן מחיר לפחות לפריט אחד או יותר')
            }else{
                show_summary.value = true
            }
        }
        //finish
        const handle_update_all_choosen_prices = async()=>{
            is_pending.value = true
            show_summary.value = false

            let temp_orders
            const docs =  await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1').collection('Orders')
            .where('status','==','open').get()
            temp_orders = docs.docs.map(doc=>doc.data())
            temp_orders = temp_orders.filter(order=>new Date().toLocaleDateString('he') == new Date(order.createdAt.seconds*1000).toLocaleDateString('he'))
            for(const item of summary_data.value){
                await update_price_item_in_db(item,temp_orders)
            }
            is_pending.value = false
        }

        const update_price_item_in_db = async(item,temp_orders)=>{
            is_pending.value = true
            item.cost = Number(item.temp_price)

            item.history_costs.push({
                cost:item.cost,
                date:new Date()
            })
            
            await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1')
            .collection('Products').doc(item.barkod)
            .set({
                history_costs:item.history_costs,
                cost:item.cost
            },{merge:true})

            delete item.temp_price

            let currents_orders
            if(!temp_orders){
                const docs =  await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1').collection('Orders')
                .where('status','==','open').get()
                currents_orders = docs.docs.map(doc=>doc.data())
                currents_orders = currents_orders.filter(order=>new Date().toLocaleDateString('he') == new Date(order.createdAt.seconds*1000).toLocaleDateString('he'))
            }else{
                currents_orders = temp_orders
            }

            await update_item_price_in_currents_orders(currents_orders,item)
            is_pending.value = false
            setTimeout(() => {
                slide_pop_successs(`מחירו של פריט ${item.ItemName} עודכן בהצלחה`)
            }, 500);

        }

        const update_item_price_in_currents_orders = async(orders,item)=>{
            if(orders.length==0 || !orders) return
            for(const order of orders){
                for(const product of order.products){
                    if(product.barkod == item.barkod){
                         product.cost = item.cost
                         break;
                     }
                }
                await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1').collection('Orders')
                .doc(order.orderId).set({
                    products:order.products
                },{merge:true})
            }
        }

        const handle_close = ()=>{
            emit('close')
        }

        const handle_print = ()=>{
            var styleElem = document.createElement('style');
            styleElem.type = 'text/css' ;
            var css = `
                @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
                *{
                    direction:rtl;
                    font-family: 'Rubik', sans-serif;
                }
                .prices-list-wrapper-content{
                    width: 100%;
                    height: 100%;
                }
                table {
                    border-collapse: collapse;
                    width: 100%;
                }
                table td, table th {
                    border: 1px solid #ddd;
                    padding: 2px;
                }
                table tr:nth-child(even){background-color: #f2f2f2;}
                table tr:nth-child(odd){background-color: #e7e6e6;}
                table tr:hover {background-color: #ddd;}
                table td{
                    text-align: center;
                    color: #161D31;
                    font-size: 16px;
                }
                table th {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: center;
                    background-color: #7367f0;
                    color: white;
                    position: sticky;
                    top: 0;
                    font-size: 10px;
                    font-weight: 500;
                    z-index: 19;
                }
                @media print {
                    .noprint {
                        visibility: hidden;
                    }
                }
            `;
            if(styleElem.styleSheet){
                styleElem.styleSheet.cssText = css;
            }
            else{
                styleElem.appendChild(document.createTextNode(css));
            }
            var divToPrint=document.getElementById("prices-list-wrapper-content");
            let newWin= window.open('', '', 'height=500, width=500');
            newWin.document.write(divToPrint.outerHTML);
            newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
            newWin.document.close();
            newWin.print();
        }

        const init = async()=>{
            is_pending.value = true
             const docs = await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1').collection('Products')
             .where('visible','==','true').orderBy('ItemName','asc').get()
             items.value = docs.docs.map(doc=>doc.data())
             is_pending.value = false
        }

        init()

       
        return{
            handle_close,
            handle_update_price,
            handle_update_all_prices,
            handle_update_all_choosen_prices,
            handle_print,
            summary_data,
            filter_by_serach,
            search,
            items,
            show_summary,
            is_pending
        }
    }
}
</script>

<style scoped>
    .prices-list{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 18;
        padding: 0 5px;
        color: #333;
    }
    .prices-list-wrapper{
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        height: 100%;
        background: #fff;
        border-radius: 5px;
    }
    .exit-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        background: crimson;
        border-radius: 50%;
        z-index: 19;
        cursor: pointer;
        color: #fff;
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

    .prices-list-wrapper-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .print-icon{
        color: cornflowerblue;
        font-size: 30px;
        cursor: pointer;
        margin-right: 5px;
    }
    .prices-list-wrapper-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .prices-list-wrapper-content{
        width: 100%;
        height: calc(100% - 150px);
        padding: 0 5px;
        overflow-x: auto;
        overflow-y: auto;
    }
    .prices-list-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 10px;
        font-weight: 500;
        z-index: 19;
    }


</style>