<template>
  <div class="choose-branch" v-if="showChooseBranch">
    <p style="margin-bottom:5px;">בחר סניף</p>
    <Dropdown
      v-model="selectedBranch"
      :options="branches"
      placeholder="בחר סניף מתוך הרשימה"
      style="width:300px; margin-bottom:5px;"
      scrollHeight="260px"
    />
    <Button
      label="המשך להזמנה"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-warning"
      @click="showNewOrder"
    />
  </div>

  <Dialog
    header="רשימת מוצרים לבחירה"
    v-model:visible="showOrders"
    @hide="whenDialogClose"
    position="top"
  >
    <div class="container-dialog">
      <div class="search-bar" style="width:100%">
        <span class="p-input-icon-left" style="width:100%; margin-bottom: 1rem">
          <i class="pi pi-search" />
          <InputText
            type="text"
            v-model="searchString"
            placeholder="חפש"
            style="width:100%;"
          />
        </span>
      </div>
      <div class="filter-row">
        <p>סנן לפי:</p>
        <Dropdown
          v-model="selectedFilter"
          :options="filterOptions"
          placeholder="בחר מסנן"
          style="width:80%"
        />
      </div>

      <div class="headlines">
        <p>ברקוד</p>
        <p>שם מוצר</p>
        <!-- <p>כמות מלאי</p> -->
        <p>הזן כמות</p>
      </div>
      <div class="product-list">
        <template v-for="product in sortedProducts" :key="product.barkod">
          <div class="product-line" :id="product.barkod">
            <div class="product-id" style="width:20%;">
              {{ product.barkod }}
            </div>
            <div
              class="product-name"
              style="font-size: 1rem; width:50%; overflow:hidden;"
            >
              <p>{{ product.ItemName }}</p>
            </div>
            <!-- <div class="stock-amount" style="width:25%;">
            <p>{{ product.stock }}</p>
          </div> -->
            <div
              class="product-stock"
              style="font-size: 1.2rem; width:20%; height:90%;"
            >
              <InputText
                type="text"
                v-model="product.currentOrder"
                style="width:100%;"
              />
            </div>
          </div>
        </template>
      </div>
      <div class="notes">
        <textarea
          v-model="notes"
          style="height:40px;"
          placeholder="הערות..."
        ></textarea>
      </div>
      <div>
        <Button
          v-if="!orderCheck"
          label="הזן נתונים"
          style="width:100%; height:50px; font-size:1.25rem; margin-top:10px"
          class="p-button-success"
          disabled
        />
        <Button
          v-if="orderCheck"
          label="בדוק לפני שליחה"
          style="width:100%; height:50px; font-size:1.25rem; margin-top:10px"
          class="p-button-success"
          @click="handleConfirmation"
        />
      </div>
    </div>
  </Dialog>

  <!-- for orders -->
  <Dialog
    header="נא לוודא!"
    v-model:visible="displayConfirmation"
    :style="{ width: '100%' }"
    :modal="true"
  >
    <div class="contianer-confirmation">
      <div class="confirmation-content" style="margin-top:1rem ;">
        <strong>נא לבדוק לפני שליחה!</strong>
      </div>
      <div class="products-before-update">
        <table>
          <tr>
            <th>ברקוד</th>
            <th>שם מוצר</th>
            <th>כמות</th>
          </tr>
          <template
            v-for="product in orderedProductsForDialog"
            :key="product.barkod"
          >
            <tr>
              <td>{{ product.barkod }}</td>
              <td>{{ product.ItemName }}</td>
              <td>{{ product.currentOrder }}</td>
            </tr>
          </template>
        </table>
      </div>
      <div class="dialog-footer">
        <Button
          label="לא לשלוח"
          @click="displayConfirmation = !displayConfirmation"
          class="p-button-danger"
        />
        <Button
          label="כן לשלוח"
          @click="updateStock"
          class="p-button-success"
        />
      </div>
    </div>

    <!-- JUST FOR MAIL -->
    <div class="form-email" id="form-email" v-show="false">
      <h1 style="text-align:center;">
        <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2">
      </h1>
      <h1 style="text-align:center;">מס' הזמנה: {{order_number}}</h1>
      <h2 style="text-align:center;">הזמנה חדשה: סניף {{selectedBranch}}</h2>
      <h3 style="text-align:center;">תאריך: {{new Date().toLocaleString('he')}}</h3>
      <table
        style="width:100%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; color:#333; width:20%;">יצרן</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; width:20%;">שם</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; width:13.33%;">כמות</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; width:13.33%;">משקל</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; color:#333; width:13.33%;">מחיר</th>
          <th style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center; color:#333; width:20%;">הערות</th>
        </tr>
        <template
            v-for="product in orderedProductsForDialog"
            :key="product.barkod"
          >
              <tr>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">{{ product.ItemName }}</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;">{{ product.currentOrder }}</td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
                <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; text-align: center;"></td>
              </tr>
        </template>
      </table>
      <h1 style="text-align:center;">משטחים:________________</h1>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import { computed, onMounted, onUnmounted, ref } from "vue";
import Dropdown from "primevue/dropdown";
import swal from "sweetalert";
import InputText from "primevue/inputtext";
import {projectFirestore,projectAuth,projectFunctions,firebase} from '../../../../firebase/config'
import {get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
// import use_klitat_oved_storage from '../../../../Methods/use_klitat_oved_storage'
// import html2pdf from "html2pdf.js";

/* eslint-disable */
export default {
  emits:['close'],
  components:{Dropdown,InputText,Dialog},
  setup(_,{emit}){
    // const { url, uploadImage} = use_klitat_oved_storage();
    const order_number = ref()
    const orderCheck=ref(computed(()=>{
      const arr=products.value.filter(product=>product.currentOrder)
      return arr.length>0?true:false
    }))
    const searchString=ref('')
    const selectedFilter= ref('הכל')
    const filterOptions = ref(["תפזורת","ארוזים","הכל"])
    const error=ref(false)
    const showChooseBranch= ref(true)
    const selectedBranch = ref("")
    const showOrders = ref(false)
    const notes=ref('')
    const branches = ref([]);

    const showNewOrder=()=>{
        if(!selectedBranch.value){
            swal("בעיה", "עליך לבחור סניף", "warning");
        }else{
            showOrders.value=!showOrders.value
            showChooseBranch.value=!showChooseBranch.value
        }
    }

    const amount=ref({})
    const products=ref([]);
    let sortedProducts = ref(computed(()=>{

      if(searchString.value==""){
        let temp=[]
        if(selectedFilter.value=="הכל"){
            return products.value
        }else if(selectedFilter.value=="תפזורת"){
          temp= products.value.filter(product=>product.categories==='תפזורת')
          return temp
        }else{
          temp= products.value.filter(product=>product.categories==='ארוזים')
          return temp
        }
      }else{
        let temp=[]
        if(Number(searchString.value)>0){
            if(selectedFilter.value=="הכל"){
            return products.value.filter(product=>product.barkod.includes(searchString.value))
            }else if(selectedFilter.value=="תפזורת"){
              temp= products.value.filter(product=>product.categories==='תפזורת' && product.barkod.includes(searchString.value))
              return temp
            }else{
              temp= products.value.filter(product=>product.categories==='ארוזים' && product.barkod.includes(searchString.value))
              return temp
        }
        }else{
            if(selectedFilter.value=="הכל"){
            return products.value.filter(product=>product.ItemName.includes(searchString.value))
            }else if(selectedFilter.value=="תפזורת"){
              temp= products.value.filter(product=>product.categories==='תפזורת' && product.ItemName.includes(searchString.value))
              return temp
            }else{
              temp= products.value.filter(product=>product.categories==='ארוזים' && product.ItemName.includes(searchString.value))
              return temp
        }
        }
      }  
    }))


    
    const whenDialogClose=()=>{
        showChooseBranch.value=!showChooseBranch.value
    }

    const displayConfirmation=ref(false)

    const orderedProductsForDialog=ref([])

    const handleConfirmation=()=>{
      orderedProductsForDialog.value = products.value.filter(product=>product.currentOrder)
      displayConfirmation.value=!displayConfirmation.value
    }

    const updateStock= async()=>{
        error.value=false
        const orderedProducts=products.value.filter(product=>product.currentOrder)
      
        // validation
        orderedProducts.forEach((product)=>{
          if(Number(product.currentOrder)<0 || isNaN(product.currentOrder) ){
              swal("בעיה", "אחד או יותר מהנתונים אינו תקין", "warning");
              error.value=true
              return
          }
        })

        if(error.value) return

        try{
          await get_order_number()
        }catch(err){
          console.log(err.message);
          swal("אירעה שגיאה",`${err.message}`, "error");
          error.value=true
        }
        if(!error.value && orderedProducts.length>0){
          orderedProducts.forEach(async(product)=>{
              try{  
                  const diff =  Number(product.stock) - Number(product.currentOrder)
                  await projectFirestore
                  .collection("Applications")
                  .doc("iW7LKA8GsRduHDOSFxq1")
                  .collection('Products')
                  .doc(product.barkod) 
                  .set({
                    stock: diff
                  },{merge:true})
              }catch(err){
                  console.log(err.message);
                  swal("אירעה שגיאה",`${err.message}`, "error");
                  error.value=true
              }
          })
        }
        if(!error.value){
          try{
            const doc =  projectFirestore.collection("Applications").doc("iW7LKA8GsRduHDOSFxq1").collection('Orders').doc()
            await doc.set({
              orderId:doc.id,
              branche: selectedBranch.value,
              createdAt: new Date(),
              user: projectAuth.currentUser.email,
              products: orderedProducts,
              status: "open",
              notes: notes.value,
              order_number:order_number.value
            })
            //פה ישלח מייל לדהן
            //await convertToPDF()
            sendEmailToDahan()
            swal({title: "עודכן בהצלחה",icon: "success",button: "סגור",})
            .then(()=>{
              displayConfirmation.value=!displayConfirmation.value
              emit('close')
            })
          }catch(err){
            console.log(err.message);
            swal("אירעה שגיאה",`${err.message}`, "error");
          }
        }    
    }

    // const convertToPDF = async () => {
    //   const wrapper = document.getElementById("form-email").innerHTML;
    //   const opt = {
    //     margin: 0,
    //     filename: `rosman-${8}.pdf`,
    //     pagebreak:{ mode: '', before: '.before', after: '.after', avoid: '.avoid' },
    //     image: { type: "jpeg", quality: 0.98 },
    //     html2canvas: { dpi: 192, letterRendering: true },
    //     jsPDF: {
    //       unit: "in",
    //       format: "a4",
    //       orientation: "portrait",
    //       compressPDF: true
    //     }
    //   };

    //   const pdf = await html2pdf()
    //     .set(opt)
    //     .from(wrapper)
    //     .save()
    //     await uploadImage(pdf,'123','dahan_pdf');
    // };

    function sendEmailToDahan() {
      //dahank62@gmail.com
      projectFunctions.httpsCallable('send_email')({
        html: document.getElementById("form-email").innerHTML,
        destinations:["mosescorcias@gmail.com","setonaut@gmail.com","veg.fruitrosman@gmail.com"],
        from:"רוסמן הזמנות",
        title:`הזמנת ירקות סניף ${selectedBranch.value}, מס הזמנה: ${order_number.value}`,
      })
    }

    const get_order_number = async()=>{
      await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1')
      .update({order_number:firebase.firestore.FieldValue.increment(1)})
      const doc = await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1').get()
      order_number.value = doc.data().order_number
    }

    let unsub
    const getProducts=async()=>{
        unsub = projectFirestore.collection("Applications")
        .doc("iW7LKA8GsRduHDOSFxq1")
        .collection('Products')
        .orderBy('ItemName')
        .onSnapshot(snap=>{
            snap.docChanges().forEach((change) => {
              if (change.type === "added") {
                  if(change.doc.data().visible=="true"){
                    products.value.push(change.doc.data())
                  }
              }
              if (change.type === "modified") {            
              const index = products.value.findIndex((product)=>product.barkode==change.doc.data().barkod)
                if(index!=-1){
                    products.value[index]=change.doc.data()
                }
              }
              if(change.type === "removed"){
                const index = products.value.findIndex((product)=>product.barkode==change.doc.data().barkod)
                if(index!=-1){
                    products.value.splice(index,1)
                }
              }
            })
        })         
    }
    onMounted(async ()=>{
      branches.value = await get_branches_from_db()
      await getProducts()
  })

    onUnmounted(()=>{
      if(unsub){
          unsub()
      }
  })

    return{
      displayConfirmation,
      handleConfirmation,
      orderedProductsForDialog,
      orderCheck,
      searchString,
      selectedBranch,
      showChooseBranch,
      branches,
      showNewOrder,
      showOrders,
      amount,
      sortedProducts,
      products,
      whenDialogClose,
      updateStock,
      notes,
      selectedFilter,
      filterOptions,
      order_number
    }
  }
  
};
</script>

<style scoped>
.choose-branch {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow:hidden;
}

.product-line {
  display: flex;
  width: 100%;
  line-height: 1.3rem;
  height: 1.3rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.product-list {
  overscroll-behavior: contain;
  display:-webkit-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 3px;
  margin-top: 20px;
  padding: 10px 0;
}

.stock-amount{
   text-align: center; 
   padding-left: 1rem;
 
}

.headlines{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    font-weight: bold;
    text-decoration: underline; 
}

.notes textarea{
  width: 100%;
  height: 2rem;
  font-size: 25px;
}
.notes textarea::placeholder{
  font-size: 20px;
  margin: 10px;
}

.filter-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
}

.contianer-confirmation{
  height: 60vh;
  overflow:hidden;
  
}
.products-before-update{
  height: 50vh;
  overflow-y: auto;
}

 table{
  margin: 1rem 0;
  font-size: 1.1rem;
  border-collapse: collapse;
  width: 100%;
}

 table td,th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  text-align: center;
}

 table td:last-child,th:last-child{
  color: red;
}
 table tr:nth-child(even){
  background-color: #dddddd;
}
.dialog-footer{
  display: flex;
  justify-content: space-evenly;
}
.container-dialog{
  width: 40vw;
  height: 70vh;
}

@media screen and (max-width: 600px) {
  .container-dialog{
        width: 90vw;
        height: 80vh;
        overflow:hidden;
   }
}

</style>
