<template>
  <div class="container">
    <div class="header">
      <div class="search">
        <span class="p-input-icon-left" style="width:80%">
          <i class="pi pi-search" />
          <InputText
            type="text"
            v-model="search"
            placeholder="חיפוש"
            style="width:100%"
          />
        </span>
      </div>
    </div>
    <div class="content">
      <table>
        <tr>
          <th @click="handleSortTable('תאריך', !selectedSorted.order)">
            תאריך
          </th>
          <th @click="handleSortTable('זמן', !selectedSorted.order)">זמן</th>
          <th @click="handleSortTable('סניף', !selectedSorted.order)">סניף</th>
          <th @click="handleSortTable('סיבה', !selectedSorted.order)">
            סיבת הדיווח
          </th>
          <th @click="handleSortTable('תיאור', !selectedSorted.order)">
            תיאור
          </th>
          <th @click="handleSortTable('סטטוס', !selectedSorted.order)">
            סטטוס
          </th>
          <th style="text-align:center;">משוב</th>
          <th style="text-align:center;">
            <Checkbox v-model="checkeAll" :binary="true" />
          </th>
        </tr>
        <template v-for="report in sortedRequests" :key="report.id">
          <tr>
            <td>
              {{
                new Date(report.createdAt.seconds * 1000).toLocaleDateString(
                  "he"
                )
              }}
            </td>
            <td>
              {{
                new Date(report.createdAt.seconds * 1000).toLocaleTimeString(
                  "he"
                )
              }}
            </td>
            <td>{{ report.branche }}</td>
            <td>{{ report.reason }}</td>
            <td>{{ report.description }}</td>
            <td v-if="report.status == 'חדש'" style="color:green;">
              {{ report.status }}
            </td>
            <td v-if="report.status == 'ממתין'" style="color:#FAB710;">
              {{ report.status }}
            </td>
            <td v-if="report.status == 'טופל'" style="color:red;">
              {{ report.status }}
            </td>
            <td style="text-align:center;">
              <i class="pi pi-pencil" @click="handleMashov(report)"></i>
            </td>
            <td style="text-align:center;">
              <Checkbox
                name="report"
                :value="{id:report.id,paths:report.uploadedDocuments}"
                v-model="checkReports"
              />
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>

  <Dialog
    header="חלון מחיקה"
    v-model:visible="showDeleteDialog"
    :style="{ width: '30vw' }"
    position="bottom"
  >
    <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
    <template #footer>
      <Button
        label="לא"
        @click="handleDeleteChecks('לא')"
        class="p-button-success"
      />
      <Button
        label="כן"
        @click="handleDeleteChecks('כן')"
        class="p-button-danger"
      />
    </template>
  </Dialog>

  <Dialog header="משוב" v-model:visible="showMashov">
    <div class="mashov-container">
      <div class="box-1">
        <div class="details">
          <div class="detail">
            <h3>תאריך וזמן</h3>
            <p>
              {{
                new Date(choosedReport.createdAt.seconds * 1000).toLocaleString(
                  "he"
                )
              }}
            </p>
          </div>
          <div class="detail">
            <h3>סניף</h3>
            <p>{{ choosedReport.branche }}</p>
          </div>
          <div class="detail">
            <h3>סיבת הדיווח</h3>
            <p>{{ choosedReport.reason }}</p>
          </div>
          <div class="detail">
            <h3>תיאור</h3>
            <p>{{ choosedReport.description }}</p>
          </div>
          <div class="detail">
            <h3>סטטוס</h3>
            <p v-if="choosedReport.status == 'חדש'" style="color:green">
              {{ choosedReport.status }}
            </p>
            <p v-if="choosedReport.status == 'ממתין'" style="color:#FAB710">
              {{ choosedReport.status }}
            </p>
            <p v-if="choosedReport.status == 'טופל'" style="color:red">
              {{ choosedReport.status }}
            </p>
          </div>
        </div>
        <div class="details-actions">
          <h4>נא לסמן סטטוס טיפול ולתעד איך אתה מתכוון לטפל בדיווח זה</h4>
          <Dropdown
            v-model="choosedReport.status"
            :options="statusOptions"
            placeholder="סטטוס טיפול"
            style="width:100%"
          />
          <Textarea
            v-model="choosedReport.tihud"
            rows="5"
            cols="30"
            style="width:100%; margin-top:5px;"
            placeholder="נא לכתוב את התיעוד כאן"
          />
          <Button
            label="עדכן"
            class="p-button-secondary"
            style="width:100%; margin-top:5px;"
            @click="handleSubmitMashov"
          />
        </div>
      </div>
      <div class="box-2">
        <h2 style="text-align:center;">גלריית תמונות להמחשת הדיווח</h2>
        <div class="images">
          <template
            v-for="image in choosedReport.uploadedDocuments"
            :key="image.url"
          >
            <div class="image">
              <img :src="image.url" @click="handleOpenImg(image.url)" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import {projectFirestore} from '../../../../../../firebase/config'
import Dialog from 'primevue/dialog';
import use_yerakot_storage from '../../../../../../Methods/use_yerakot_storage'
import swal from "sweetalert";
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
export default {
    components:{InputText,Checkbox,Dialog,Dropdown,Textarea},
    setup(){
        const { deleteImage } = use_yerakot_storage();
        const statusOptions=ref([
            'חדש',
            'ממתין',
            'טופל'
        ])
        const error=ref('')
        const showDeleteDialog=ref(false)
        const checkReports=ref([])
        const checkeAll=ref(false)
        const search=ref('')
        const reports=ref([])
        const showMashov=ref(false)
        const choosedReport=ref({})

        const handleMashov=(report)=>{
            choosedReport.value=report
            showMashov.value=!showMashov.value
        }
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const sortedRequests=ref(computed(()=>{
            if (selectedSorted.value.name == "")return filterReportsBySearch.value;
            if (selectedSorted.value.name == "תאריך") {
                return filterReportsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.createdAt.seconds * 1000).toLocaleDateString('he') >  new Date(b.createdAt.seconds * 1000).toLocaleDateString('he')
                        ? 1
                        : new Date(b.createdAt.seconds * 1000).toLocaleDateString('he') > new Date(a.createdAt.seconds * 1000).toLocaleDateString('he')
                        ? -1
                        : 0;
                    }
                    return new Date(a.createdAt.seconds * 1000).toLocaleDateString('he') > new Date(b.createdAt.seconds * 1000).toLocaleDateString('he')
                    ? -1
                    : new Date(b.createdAt.seconds * 1000).toLocaleDateString('he') > new Date(a.createdAt.seconds * 1000).toLocaleDateString('he')
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "זמן") {
                return filterReportsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he') >  new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he')
                        ? 1
                        : new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he') > new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he')
                        ? -1
                        : 0;
                    }
                    return new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he') > new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he')
                    ? -1
                    : new Date(b.createdAt.seconds * 1000).toLocaleTimeString('he') > new Date(a.createdAt.seconds * 1000).toLocaleTimeString('he')
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סניף") {
            return filterReportsBySearch.value.sort((a, b) => {
                if (selectedSorted.value.order) {
                return a.branche > b.branche
                    ? 1
                    : b.branche > a.branche
                    ? -1
                    : 0;
                }
                return a.branche > b.branche
                ? -1
                : b.branche > a.branche
                ? 1
                : 0;
                });
            }
            if (selectedSorted.value.name == "תיאור") {
                return filterReportsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.description > b.description
                        ? 1
                        : b.description > a.description
                        ? -1
                        : 0;
                    }
                    return a.description > b.description
                    ? -1
                    : b.description > a.description
                    ? 1
                    : 0;
                    });
            }
            if (selectedSorted.value.name == "סטטוס") {
                return filterReportsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.status > b.status
                        ? 1
                        : b.status > a.status
                        ? -1
                        : 0;
                    }
                    return a.status > b.status
                    ? -1
                    : b.status > a.status
                    ? 1
                    : 0;
                    });
            }
            if (selectedSorted.value.name == "סיבה") {
                return filterReportsBySearch.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return a.reason > b.reason
                        ? 1
                        : b.reason > a.reason
                        ? -1
                        : 0;
                    }
                    return a.reason > b.reason
                    ? -1
                    : b.reason > a.reason
                    ? 1
                    : 0;
                    });
            }
        }))

        const filterReportsBySearch=ref(computed(()=>{
             if (search.value == "") return reports.value
             return reports.value.filter(report=>{
                 if(new Date(report.createdAt.seconds * 1000).toLocaleDateString('he').includes(search.value)) return report
                 if(new Date(report.createdAt.seconds * 1000).toLocaleTimeString('he').includes(search.value)) return report
                 if(report.branche.includes(search.value))return report
                 if(report.reason.includes(search.value))return report
                 if(report.description.includes(search.value))return report
                 if(report.status.includes(search.value))return report

             })
        }))

        const handleSortTable=(name, order)=>{
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        }

        const getReportsFromDB=()=>{
            const unsub = projectFirestore
            .collection("Applications")
            .doc("iW7LKA8GsRduHDOSFxq1")
            .collection("Reports")
            .onSnapshot(querySnapshot => {
            reports.value = [];
            querySnapshot.forEach(doc => {
                reports.value.push(doc.data());
            });
            });

            watchEffect(onInvalidate => {
                onInvalidate(() => unsub());
            });
        }

        const handleDeleteChecks=(option)=>{
            if(option=='לא'){
                showDeleteDialog.value=false
                checkeAll.value=false
                checkReports.value=[]
            }
            if(option=='כן'){

                
                checkReports.value.forEach(async report=>{
                    
                    for (const path of report.paths) {
                        await deleteImage(path.filePath)
                    }
                    await projectFirestore
                    .collection("Applications")
                    .doc("iW7LKA8GsRduHDOSFxq1")
                    .collection("Reports")
                    .doc(report.id).delete()
                    checkeAll.value=false
                    showDeleteDialog.value=false
                    checkReports.value=[]
                })
            }
        }
        const handleOpenImg=(url)=>{
           window.open(url, '_blank')
        }
        const validation=()=>{
            for (let key in choosedReport.value) {
                if (key == "tihud") {    
                    if (choosedReport.value[key].length == 0) {
                        error.value = "חובה עליך לתעד";
                        return false;
                    }
                }
            }
            return true
        }
        const handleSubmitMashov=async()=>{
            if(!validation()){
               swal("בעיה", `${error.value}`, "warning");
            }else{
                 await projectFirestore
                .collection("Applications")
                .doc("iW7LKA8GsRduHDOSFxq1")
                .collection("Reports")
                .doc(choosedReport.value.id)
                .update(choosedReport.value)
                swal("הצלחה", "עודכן בהצלחה", "success")
                .then(()=>{
                    showMashov.value = false
                });
            }
        }
        watch(checkReports,()=>{
            if(checkReports.value.length>0){
                showDeleteDialog.value=true
            }
            else {
                showDeleteDialog.value=false
            }
        })
        watch(checkeAll,()=>{  
            if(checkeAll.value){
                checkReports.value=reports.value.map(report=>({id:report.id,paths:report.uploadedDocuments}))
            }
            else{
                 checkReports.value=[]
            }
        })
        onMounted(()=>{
            getReportsFromDB();
        })
        return{search,reports,checkeAll,checkReports,showDeleteDialog,
        handleDeleteChecks,filterReportsBySearch,sortedRequests,
        selectedSorted,handleSortTable,showMashov,
        handleMashov,choosedReport,statusOptions,error,handleSubmitMashov,handleOpenImg}
    }
}
</script>

<style scoped>
    .container{
        width: 90vw;
        height: 70vh;
        overflow:hidden;
    }
    .header {
        width: 100%;
        height: 10vh;
        display: flex;
        justify-content: space-between;
    }
    .search {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .content {
        width: 100%;
        height: 90vh;
        overflow-y: auto;
        padding: 0.5rem;
    }
    table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }
    td,
    th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    text-align: right;
    }
    tr {
      cursor: pointer;
    }
    tr:hover,
    tr:nth-child(even):hover {
    background-color: #b8d1f3;
    }
    tr:nth-child(even) {
    background-color: #dddddd;
    }
    td .pi-pencil:hover {
        color: #fab710;
    }
    .mashov-container{
        width: 70vw;
        height: 60vh;
        display: flex;
    }

    .box-1{
        width: 50%;
        height: 100%;
    }
    .box-2{
        width: 50%;
        height: 100%;
        overflow:hidden;
    }
    .images{
        margin-top: 10px;
        width: 100%;
        height: 80%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .image{
        width: 100%;
        height: 100%;
    }
    .image img{
        width: 100%;
        cursor: pointer;
       
    }

    .details{
        width: 100%;
        height: 50%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .details-actions{
        width: 100%;
        height: 50%;
    }
</style>